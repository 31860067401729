import React from "react";
import Comingsoon from "../components/Comingsoon";
import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

const Home = ({ children }) => {
  return (
    <div className="home">
      {/* <Header /> */}
      <Comingsoon className="main" role="main">
        {children}
      </Comingsoon>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
