import React, { useEffect, useState } from "react";
import comingsoon1 from "../assets/image/comingsoon1.jpg";
import comingsoon2 from "../assets/image/comingsoon2.jpg";
import logo from "../assets/image/logo.png";
import comingsoon from "../assets/image/comingsoon.gif";

const Main = () => {
  const images = [comingsoon1, comingsoon2];
  const [currentIdx, setCurrentIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIdx((prevIdx) => (prevIdx + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="comingsoon_container">
      <img src={images[currentIdx]} alt={images[currentIdx]} />
      <img className="logo" src={logo} alt="logo" />
      <img className="comingsoon" src={comingsoon} alt="comingsoon" />
    </div>
  );
};

export default Main;
